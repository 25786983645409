var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid mt-5"},[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('div',{staticClass:"row"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_CAFETERIAS))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List Cafeterias' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-shop"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.CAFETERIAS")))])])]}}],null,false,941497672)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_ESTABLISHMENTS))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List Establishments' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-building"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.ESTABLISHMENTS")))])])]}}],null,false,1243653213)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_SERVICECENTERS))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List ServiceCenters' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-square-pin"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.SERVICECENTERS")))])])]}}],null,false,1896429163)}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }